<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <v-card class="user-profile-container user-catalogue">
      <div class="close-btn-wrapper" v-if="!modalPosition">
        <v-btn class="close-btn" @click="closeProfileModal()">
          <v-icon class="close-modal-icon">fa-times</v-icon>
        </v-btn>
        <v-btn v-if="isMobile && activeCategory==null" class="filter-icon mt-2" @click="openCategoryModal()">
          <v-icon>fa-filter</v-icon>
        </v-btn>
        <v-btn v-if="isMobile && activeCategory!=null" class="filter-icon mt-2" @click="setCategory(null)">
          <v-icon>fa-rotate-right</v-icon>
        </v-btn>
      </div>
      <v-card-text class="main-card" v-if="auctions">
        <v-container :class="{'no-max-width': gridView}">
          <div>
            <v-row align="center" class="form-container justify-center flex-column">
              <v-col sm="12" class="full-width">
                <h2 class="page-title text-center">{{ $t('Past Auctions') }}</h2>
                <!-- <v-col class="main-col-wrapper" v-if="!preselectedAuction || localySelected">
                  <v-col align="center" class="sub-col-wrapper">
                    <v-row>
                      <search-box
                        v-if="auctions && auctions.length > 0"
                        :currentAuction="currentAuction"
                        :auctions="auctions.filter(el => el.catalogue_enabled)"
                        hide-create-new
                        title=""
                        @onSelectAuction="selectAuction"
                        show-auction-type
                      />
                    </v-row>
                  </v-col>
                </v-col> -->
              </v-col>
              <div class="scroll-anchor" ref="anchor" />
              <v-progress-circular v-if="auctionLoading" class="loading-icon mt-6" indeterminate />
              <template v-else>
                <h3 class="auction-title" v-if="selectedAuctionData">{{ auctionName(selectedAuctionData) }}</h3>
                <template v-if="selectedAuctionData && selectedAuctionData.sale_dates && selectedAuctionData.sale_dates.dates">
                  <h4
                    class="auction-date"
                    v-for="(date, i) in selectedAuctionData.sale_dates.dates"
                    :key="i"
                  >
                    {{ getFormattedDate(date, appStoreLang, false ,false) }}
                  </h4>
                </template>
                <v-col sm="12" v-if="!_.isEmpty(currentAuction) && !error && !_.isEmpty(articles)">
                  <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    :label="$t('Search lots by lot number or name')"
                    hide-details
                    class="mb-3"
                    @input="searchHandler"
                  />
                  <v-switch
                    v-model="showUnsold"
                    color="primary"
                    :label="$t('Show unsold articles only')"
                    @change="searchHandler"
                    hide-details
                    class="mb-2"
                    v-if="displayShowUnsoldSlider"
                  />
                  <div class="catalogue__header">
                    <h3 class="catalogue__sidebar-title" v-if="!categoriesError">{{ $t('Categories') }}</h3>
                    <div v-else />
                    <div class="catalogue__header-buttons">
                      <v-icon class="icon-with-hover" :class="{ active: !gridView }" @click="gridView = !gridView">fa-list</v-icon>
                      <v-icon class="icon-with-hover" :class="{ active: gridView }" @click="gridView = !gridView">fa-th</v-icon>
                    </div>
                  </div>
                  <div class="catalogue" :class="{ 'list-view': !gridView }" :key="currentAuction.id">
                    <div class="catalogue__sidebar" v-if="categories && categories.length && !categoriesError">
                      <category-modal
                        ref="categoryModal"
                        @setCategory="setCategory"
                        :setCategory="setCategory"
                        :categories="categories"
                        :activeCategory="activeCategory"
                        :isMobile="isMobile"
                        v-if="isMobile"
                      />
                      <categories-filter
                        :appStoreLang="appStoreLang"
                        @setCategory="setCategory"
                        :categories="categories"
                        :activeCategory="activeCategory"
                        :isMobile="isMobile"
                        v-else
                      />
                    </div>
                    <div class="catalogue__list" :key="tableKey" v-if="renderComponent">
                      <template v-if="articlesView.length">
                        <div
                          class="catalogue__item"
                          :class="{ highlight: article.is_highlight }"
                          v-for="(article, i) in articlesView"
                          :key="article.id"
                          ref="article"
                          @click="gridView ? openArticleDetailedModal(article) : false"
                          :style="gridView && itemsPerRow >= 3 ? `width: calc(${100 / itemsPerRow}% - 30px)` : ''"
                        >
                          <div class="catalogue__item-header">
                            <p class="catalogue__item-highlight" v-if="article.is_highlight">{{ $t('Highlight') }}</p>
                            <v-icon class="icon-search-circle icon-with-hover mr-2" small v-if="gridView || isMobile" @click.stop="openArticleDetailedModal(article)">fa-search</v-icon>
                            <v-tooltip top allow-overflow>
                              <template #activator="{ on, attrs }">
                                <svg
                                  v-bind="attrs"
                                  v-on="on"
                                  height="30px"
                                  width="30px"
                                  :fill="globalTheme === 'dark' ? '#ffffff' : '#000000'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 101"
                                  viewBox="0 0 100 100" x="0px" y="0px"
                                  class="catalogue__item-shipping icon-with-hover mr-2"
                                  v-if="article.no_shipping || selectedAuctionData.no_shipping"
                                >
                                  <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
                                </svg>
                              </template>
                              <span>{{ $t('No shipping possible') }}</span>
                            </v-tooltip>
                            <v-tooltip top allow-overflow v-if="enableArticleInqirires">
                              <template #activator="{ on, attrs }">
                                <div
                                  v-bind="attrs"
                                  v-on="on"
                                  class="catalogue__item-inquiry icon-with-hover mr-2"
                                  @click.stop="openArticleInquiryModal(article)"
                                  v-if="isAuthenticatedAsUser"
                                >
                                  <v-icon>fa-question-circle</v-icon>
                                </div>
                              </template>
                              <span>{{ $t('Lot inquiry') }}</span>
                            </v-tooltip>
                          </div>
                          <div class="catalogue__item-body">
                            <div class="catalogue__show-image" v-show="article.offensive && !showImages[`article${article.id}`]">
                              <div class="info-text" @click.stop="showImageToggle(article.id)" v-if="isAuthenticatedAsUser">
                                <v-icon>fa-info-circle</v-icon>
                                <p>
                                  {{ $t('offensive_article_button_show_image') }}<br>
                                  <a :href="appSettings.url_rules" target="_blank">{{ $t('offensive_article_button_show_image2') }}</a>
                                </p>
                              </div>
                              <v-btn class="btn old-styling-btn" @click.stop="openLogin" v-else>{{ $t('Login to see images') }}</v-btn>
                            </div>
                            <div
                              class="catalogue__image"
                              ref="carousel"
                              v-if="article.images && article.images.length && article.images.length <= 1"
                              v-show="showImages[`article${article.id}`] || !article.offensive"
                              @click.stop="e => selectImage(e, article.images, 0)"
                            >
                              <v-img contain :src="article.images[0].image"></v-img>
                            </div>
                            <div class="catalogue__slider" v-else-if="article.images && article.images.length" v-show="showImages[`article${article.id}`] || !article.offensive">
                              <div class="custom-arrow slick-arrow slick-next" @click.stop="$refs.carousel[i].next()">
                                <v-icon class="arrow-left">fa-angle-right</v-icon>
                              </div>
                              <div class="custom-arrow slick-arrow slick-prev" @click.stop="$refs.carousel[i].prev()">
                                <v-icon class="arrow-right">fa-angle-left</v-icon>
                              </div>
                              <VueSlickCarousel ref="carousel" lazyLoad="ondemand" :arrows="false" :key="article.images.length" :dots="false">
                                <div
                                  class="image-item"
                                  v-for="(itm, idx) in article.images"
                                  :key="itm.articleNumber"
                                  @click.stop="e => selectImage(e, article.images, idx)"
                                  @keydown.left.stop=""
                                  @keydown.right.stop=""
                                >
                                  <v-img contain class="article-image" :src="itm.image" :style="{'max-height': isMobile ? '114px' : '156px'}"></v-img>
                                </div>
                              </VueSlickCarousel>
                            </div>
                            <div class="catalogue__image cursor-pointer" ref="carousel" v-else />
                            <div class="catalogue__item-content" :style="{ width: article.images && article.images.length ? '' : '100%' }">
                              <h3 class="catalogue__name">{{ articleTitle(article) }}</h3>
                              <v-row justify="space-between" align="center" class="ma-0">
                                <v-col cols="12" class="pt-0 pb-0 pl-0">
                                  <div class="catalogue__meta">
                                    <div class="catalogue__meta-item">
                                      <p class="catalogue__meta-name">{{ $t('Article') }}</p>
                                      <h5 class="catalogue__meta-value">#{{ article.number_optional || article.number }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="article.limit && !selectedAuctionIsSale">
                                      <p class="catalogue__meta-name" v-if="selectedAuctionData.type === 'timed'">{{ $t('Start price') }}</p>
                                      <p class="catalogue__meta-name" v-else>{{ $t('Limit') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(article.limit) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-else-if="article.buy_price && selectedAuctionIsSale">
                                      <p class="catalogue__meta-name">{{ $t('Buy price') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(article.buy_price) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type !== 'timed' && !selectedAuctionIsSale && article.estimated_price && (selectedAuctionData.catalogue_enabled === 'enabled' || selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations')">
                                      <p class="catalogue__meta-name">{{ $t('Estimate') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ article.estimated_price }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData">
                                      <p class="catalogue__meta-name">{{ $t('Current price') }}</p>
                                      <h5 class="catalogue__meta-value" v-if="article.timedData.current_hammer_price">{{ currency }} {{ euNumbers(article.timedData.current_hammer_price) }}</h5>
                                      <h5 class="catalogue__meta-value" v-else>-</h5>
                                    </div>
                                    <!--                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_from">-->
                                    <!--                                      <p class="catalogue__meta-name" v-translate>Starts at</p>-->
                                    <!--                                      <h5 class="catalogue__meta-value">{{ getFormattedDate(article.timedData.active_from, appStoreLang, true) }}</h5>-->
                                    <!--                                    </div>-->
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_until">
                                      <p class="catalogue__meta-name">{{ $t('Ends at') }}</p>
                                      <h5 class="catalogue__meta-value">{{ getFormattedDate(article.timedData.active_until, appStoreLang, true, false) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_until && getRemainingTime(article.timedData.active_until, redrawRemainingTime)">
                                      <p class="catalogue__meta-name">{{ $t('Remaining time') }}</p>
                                      <h5 class="catalogue__meta-value">{{ getRemainingTime(article.timedData.active_until, redrawRemainingTime) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'live' && selectedAuctionData.status !== 'preparing' && (selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations' || selectedAuctionData.catalogue_enabled === 'enabled_with_results')">
                                      <p class="catalogue__meta-name">{{ $t('Knockdown') }}</p>
                                      <h5 class="catalogue__meta-value" v-if="article.sold_price">{{ currency }} {{ euNumbers(article.sold_price) }}</h5>
                                      <h5 class="catalogue__meta-value" v-else>-</h5>
                                    </div>
                                    <v-row class="ma-0 justify-md-end justify-start">
                                      <v-btn
                                        class="btn catalogue__item-shopping mb-3 mb-sm-0 old-styling-btn"
                                        @click.stop="openPostAuctionSaleModal(article)"
                                        v-if="isAuthenticatedAsUser && displayPostAuctionBtn && !article.sold_price && !gridView && (article.status === '3' || article.status === '4')"
                                      >
                                        <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale') }}</span>
                                      </v-btn>
                                    </v-row>
                                  </div>
                                </v-col>
                              </v-row>
                              <p v-if="useHtml" class="catalogue__description" v-html="articleDescription(article)"></p>
                              <p v-else class="catalogue__description">{{ articleDescription(article) }}</p>
                              <v-btn
                                class="btn catalogue__item-shopping small old-styling-btn"
                                @click.stop="openPostAuctionSaleModal(article)"
                                v-if="isAuthenticatedAsUser && displayPostAuctionBtn && !article.sold_price && gridView && (article.status === '3' || article.status === '4')"
                              >
                                <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale') }}</span>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="info-text" v-else>
                        <v-icon>fa-info-circle</v-icon>
                        <p>{{ $t('No articles are found') }}</p>
                      </div>
                    </div>
                  </div>
                </v-col>
                <div class="info-text mt-6" v-else>
                  <v-icon>fa-info-circle</v-icon>
                  <p>{{ $t('No articles are found') }}</p>
                </div>
                <v-col class="text-center" v-if="!selectedAuctionData && getAuctionError">
                  <div class="status__item status__item--error">
                    <div class="status__icon" />
                    <p class="status__text">{{ $t(`This auction doesn't exist`) }}</p>
                  </div>
                </v-col>
              </template>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <div class="pagination-container" v-if="auctions && totalItems && renderComponent">
      <v-row class="ma-0" justify="center" align="center">
        <v-col class="pa-0">
          <v-row class="ma-0 d-flex" align="center" :justify="isMobile ? 'space-around' : 'start'">
            <v-menu offset-y close-on-content-click>
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  class="dropdown-btn customizable"
                >
                  <span class="highlight">{{ countPerPageDefault }}<v-icon class="ml-1">fa-angle-down</v-icon></span>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in itemsPerPage"
                  :key="index"
                  @click="changePerPage(item)"
                >
                  <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="ml-2 mr-2 pagination-pages" v-if="!isMobile">
              {{ page * countPerPageDefault + 1 }}-{{ totalPages > 1 ? totalPages === page + 1 ? totalItems : (page + 1) * countPerPageDefault : totalItems }} {{ $t('of') }} {{ totalItems }} {{ $t('items') }}
            </div>
          </v-row>
        </v-col>
        <custom-pagination
          v-if="totalPages > 1"
          :page="page + 1"
          @changePage="changePage"
          :length="totalPages"
          :totalVisible="10"
        />
      </v-row>
    </div>
    <CoolLightBox
      :items="selectedImages"
      :index="lightBoxIndex"
      :useZoomBar="true"
      :disableZoom=disableZoomOnMobile
      :closeOnClickOutsideMobile="true"
      @close="lightBoxIndex = null"
    />
    <user-article-inquiry-modal
      ref="inquiryModal"
      :article="selectedArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
    <article-details-modal
      ref="articleDetailsModal"
      :article="selectedDetailedArticle"
      :timed-error="timedError"
      :selected-auction-data="selectedAuctionData"
      :prev-article="prevArticle"
      :next-article="nextArticle"
      @openArticleDetailedModal="openArticleDetailedModal"
      @closeArticleDetailedModal="closeArticleDetailsModal"
    />
    <post-auction-sale-modal
      :post-auction-sale-modal="postAuctionSaleModal"
      :article="selectedArticle"
      @closePostAuctionSaleModal="closePostAuctionSaleModal"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapState } from 'pinia'
import UserArticleInquiryModal from '@/components/modals/user-article-inquiry-modal'
import articleDetailsModal from '@/components/modals/article-details-modal'
import articleDetails from '@/mixins/article-details'
import counter from '@/mixins/counter'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {
  timeouts,
  localFeatures,
  AuctionStatusType,
  AuctionFlavor
} from '@/utils/constants'
import CategoriesFilter from '@/components/blocks/categories-filter'
import CategoryModal from '@/components/modals/category-modal'
import CustomPagination from '@/components/blocks/custom-pagination.vue'
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import { createArticleSearchIndex, searchArticles } from '@/services/article';
import { euNumbers, getFormattedDate, getTranslatedText } from '@/services/i18n';
import PostAuctionSaleModal from '@/components/modals/user-post-auction-sale-modal.vue';
import { getImageFullUrl, getImageOrFallback } from '@/services/auction';

export default {
  name: 'PastAuctions',
  components: {
    PostAuctionSaleModal,
    VueSlickCarousel,
    CoolLightBox,
    UserArticleInquiryModal,
    articleDetailsModal,
    CategoriesFilter,
    CategoryModal,
    CustomPagination
  },
  data: () => ({
    renderComponent: true,
    showProfileModal: false,
    modalPosition: false,
    profileLocalization: {},
    currentAuction: {},
    search: '',
    countPerPageDefault: localFeatures.elementsPerPageDefault,
    itemsPerPage: localFeatures.elementsPerPage,
    page: 0,
    start: 0,
    end: 0,
    key: 0,
    error: '',
    articlesSearchFiltered: [],
    loading: false,
    searchResult: [],
    gridView: localFeatures.gridViewActiveByDefault,
    activeCategory: null,
    articlesView: [],
    modalKey: 0,
    lightBoxIndex: null,
    selectedImages: [],
    auctions: null,
    getAuctionError: false,
    dialog: false,
    question: '',
    auctionLoading: false,
    selectedArticle: {},
    showUnsold: false,
    categoriesError: false,
    tableKey: 0,
    timedError: false,
    initArticles: [],
    localySelected: true,
    itemsPerRow: localFeatures.gridViewItemsPerRow,
    showImages: {},
    visibleRegistrationAlert: true,
    features: localFeatures,
    visibleAuctionSignupAlert: true,
    auctionRequireBidLimit: false,
    totalItems: 0,
    articlesViewUnsliced: [],
    searchKeys: ['name', 'description'], // number and number_optional will always be searched
    articleSearchIndex: null,
    useHtml: localFeatures.useHtml,
    postAuctionSaleModal: false,
    enableArticleInqirires: localFeatures.enableArticleInqirires
  }),
  mixins: [
    articleDetails,
    counter
  ],
  computed: {
    ...mapState(useRootStore, ['globalTheme', 'userProfile', 'isMobile', 'appLocalization', 'productCataloguePredefinedValue', 'appFeatures', 'appSettings', 'appStoreLang', 'auctionsFetchedArticles', 'categories', 'isAuthenticatedAsUser']),
    totalPages() {
      return Math.ceil(this.totalItems / this.countPerPageDefault)
    },
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    headers() {
      return [
        {
          text: this.$t('Article #'),
          value: 'number',
        },
        {
          text: this.$t('Article name'),
          value: 'name',
        },
        {
          text: this.$t('Description'),
          value: 'description',
        }
      ]
    },
    disabled () {
      return !this.currentAuction
    },
    currency() {
      return this.appLocalization.currency
    },
    selectedAuctionData() {
      return this.auctions && this.currentAuction && this.auctions.find(el => el.id === this.currentAuction.id)
    },
    displayPostAuctionBtn() {
      return this.selectedAuctionData && (this.selectedAuctionData.catalogue_enabled === 'enabled_with_results' || this.selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations') && this.selectedAuctionData.post_auction_sale_enabled && this.selectedAuctionData.status !== AuctionStatusType.preparing && this.selectedAuctionData.type === AuctionFlavor.live
    },
    displayShowUnsoldSlider() {
      return this.selectedAuctionData && (this.selectedAuctionData.catalogue_enabled === 'enabled_with_results' || this.selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations') && this.selectedAuctionData.status === 'completed'
    },
    selectedAuctionIsSale() {
      return this.selectedAuctionData && this.selectedAuctionData.type === 'sale'
    },
    preselectedAuction() {
      return this.$route.params.id
    },
    disableZoomOnMobile() {
      return !!this.isMobile
    }
  },
  async created() {
    await this.getIntialData();
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'getArticles', 'updateArticleData', 'fetchCategories', 'SET_TEMP_ALERT']),
    getFormattedDate,
    async getIntialData() {
      await this.getAuctions()
      if (this.preselectedAuction) {
        this.auctionLoading = true
        this.localySelected = false
        this.currentAuction = this.auctions.find(el => el.code.toString() === this.preselectedAuction.toString())
        if (this.currentAuction) {
          await this.getAuctionDetail(this.currentAuction.id)
          if (this.$route.query.categoryId) this.setCategory(+this.$route.query.categoryId)
          if (this.$route.query.page) {
            if (this.$route.query.page <= this.totalPages) {
              this.changePage(this.$route.query.page);
            } else {
              this.setPage();
            }
          }
        } else {
          this.getAuctionError = true
          this.auctionLoading = false
        }
      }
      this.checkUrl()
      this.articleSearchIndex = createArticleSearchIndex(this.articles);
    },
    getFieldName(obj, field) {
      let langTarget
      if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
        langTarget = JSON.parse(obj[`${field}_i18n`])
      } else {
        langTarget = obj[`${field}_i18n`]
      }
      if (langTarget && Object.keys(langTarget).length > 0 && langTarget[this.appStoreLang]) {
        return `${field}_i18n`
      }
      return field
    },
    euNumbers,
    changePage(el) {
      // this.page = el
      this.page = el - 1
      this.setPage();
      this.getArticlesData()
      this.$nextTick(() => {
        this.$refs.anchor.scrollIntoView({ behavior: 'smooth' })
      })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    // Close profile modal without saving
    closeProfileModal () {
      this.$router.push({ name: 'dashboard' })
    },
    openCategoryModal() {
      this.$refs.categoryModal.openModal();
    },
    async getAuctionDetail(auctionID, savedPageNumber = 0, savedCategory = null, getCategories = true, getTimedData = true) {
      this.activeCategory = savedCategory
      this.page = savedPageNumber
      this.currentAuction = { ...this.currentAuction, id: +auctionID }

      await this.getArticles({ auctionID, auction: this.selectedAuctionData })
      let categoriesData = {}
      if (getCategories) {
        try {
          categoriesData = await this.fetchCategories();
          // this.categories = categoriesData
          this.categoriesError = false
        } catch (e) {
          this.categoriesError = true
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Displaying the categories is currently unavailable due to an technical error. Please try again later.') })
          // throw e
        }
      }

      let responseAuction = this.auctionsFetchedArticles[`auction_${auctionID}`].map(article => {
        if (!this.categoriesError) {
          for (let category in this.categories) {
            if (!this.categories[category].articlesCount) this.categories[category] = { ...this.categories[category], articlesCount: 0 }
            if (this.categories[category].id === article.category_id) {
              if (this.selectedAuctionData.type === 'sale') {
                if (article.status === '1' && article.instant_buy_possible) this.categories[category].articlesCount++
              } else {
                this.categories[category].articlesCount++
              }
            }
          }
        }
        return article
      })
      if (responseAuction.Message) {
        this.error = responseAuction.Message
        this.auctionLoading = false
        return
      }

      this.error = null
      this.articles = responseAuction
      this.articlesSearchFiltered = responseAuction

      if (this.isAuthenticatedAsUser && getTimedData && this.selectedAuctionData.type === 'timed') await this.getTimedData(auctionID)

      this.key++
      this.getArticlesData()
      this.auctionLoading = false
    },
    getArticlesData() {
      let articles = [...this.articlesSearchFiltered]
      if (this.showUnsold) {
        articles = articles.filter(el => !el.sold_price)
      }
      if (this.activeCategory) {
        articles = articles.filter(el => el.category_id === this.activeCategory)
        this.getAuctionError = _.isEmpty(articles)
      }
      this.totalItems = articles.length
      this.articlesView = articles.slice(this.page * this.countPerPageDefault, (this.page + 1) * this.countPerPageDefault)
      this.articlesViewUnsliced = articles
    },
    searchArticles,
    /**
     * search in the articles (all of that auction not just the ones on the current page) and save results it in articlesSearchFiltered
     */
    searchHandler: _.debounce(function () {
      this.page = 0
      let articles = [...this.articles]
      if (this.search) {
        this.articlesSearchFiltered = this.searchArticles(this.articleSearchIndex, this.search, articles, this.searchKeys, this.appStoreLang)
      } else {
        this.articlesSearchFiltered = articles
      }
      this.getArticlesData()
    }, timeouts.debounce),
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    auctionName (auction) {
      return getTranslatedText(auction, 'name', this.appStoreLang)
    },
    setCategory(id) {
      this.activeCategory = id
      if (this.$route.query.categoryId !== this.activeCategory) {
        this.page = 0;
        if (this.activeCategory === null) {
          this.$router.replace({ query: { categoryId: undefined } })
        } else {
          this.$router.replace({ query: { categoryId: id } })
        }
      }
      this.getArticlesData()
    },
    setPage() {
      if (+this.$route.query.page !== (this.page + 1)) this.$router.replace({ query: { ...this.$route.query, page: this.page + 1 } })
    },
    changePerPage(item) {
      this.countPerPageDefault = item
      this.page = 0
      this.setPage();
      this.getArticlesData()
    },
    async selectImage(e, images, index) {
      if (e.target.classList.contains('image-item')) {
        e.target.blur()
      } else {
        e.target.parentNode.blur()
      }
      let arr = []
      for (let image of images) {
        const res = await getImageOrFallback(getImageFullUrl(image.image, this.selectedAuctionData.code), image.image)
        arr.push(res)
      }
      this.selectedImages = arr
      this.lightBoxIndex = index
    },

    /**
     * Get all auctions from api and filter according to conditionals
     * @return {any} - Modified auctions
     */
    async getAuctions() { //TODO this function has some duplicates
      try {
        let data
        if (this.isAuthenticatedAsUser) {
          data = await dispatcher.getAllAuctions()
        } else {
          data = await dispatcher.getGuestAuctions()
        }
        this.auctions = data.filter(el => {
          return moment(el.active_until).diff(moment(), 'seconds') > 0 && el.catalogue_enabled !== 'disabled'
        })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    async getTimedData(id) {
      try {
        const result = await dispatcher.getArticleTimedStatus(id, "all")
        if (result.timedBids && data.data.timedBids.length) {
          result.timedBids.forEach(el => {
            this.articles.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
            this.articlesViewUnsliced.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
          })
        }
        this.timedError = false
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting timed data. Please try again later') })
        this.timedError = true
      }
    },
    selectAuction(id) {
      this.auctionLoading = true
      this.search = null
      this.getAuctionDetail(id)
      const auction = this.auctions.find(el => el.id.toString() === id.toString())
      if (auction) this.$router.push({ params: { id: auction.code } })
    },
    openArticleInquiryModal(article) {
      this.selectedArticle = article
      this.$refs.inquiryModal.open()
    },
    updateArticle(data) {
      this.selectedArticle.timedData = data
      this.updateArticleData({ auction_id: this.selectedAuctionData.id, article: { ...this.selectedArticle } })
      let articles = this.auctionsFetchedArticles[`auction_${this.selectedAuctionData.id}`]
      this.articles = articles
      this.articlesViewUnsliced = articles
      this.getArticlesData()
      this.modalKey++
    },
    showImageToggle(id) {
      this.showImages = {
        ...this.showImages,
        [`article${id}`]: true
      }
    },
    openLogin() {
      this.$router.push({ name: 'login', query: { nextUrl: this.$route.fullPath.trim() } })
    },
    openPostAuctionSaleModal(article) {
      this.selectedArticle = article
      this.postAuctionSaleModal = true
    },
    closePostAuctionSaleModal() {
      this.selectedArticle = {}
      this.postAuctionSaleModal = false
    }
  },
  watch: {
    auctionsFetchedArticles: {
      deep: true,
      handler(val) {
        if (!this.selectedAuctionData) return false
        let articles = val[`auction_${this.selectedAuctionData.id}`]
        if (!articles) return

        if (this.selectedAuctionData && this.selectedAuctionData.type === 'sale') {
          this.articles = articles.filter(el => +el.status === 1 && el.instant_buy_possible)
          this.articlesSearchFiltered = articles.filter(el => +el.status === 1 && el.instant_buy_possible)
        } else {
          const updatedArticles = articles.map(el => {
            const prevVal = this.articles.find(article => article.id === el.id)
            return {
              ...prevVal,
              ...el
            }
          })
          this.articles = updatedArticles
          this.articlesSearchFiltered = updatedArticles
        }

        this.key++
        this.getArticlesData()
        if (this.categories.length) {
          for (let category in this.categories) {
            if (!this.categories[category].articlesCount) {
              this.categories[category] = {
                ...this.categories[category],
                articlesCount: 0
              }
            }
            this.categories[category].articlesCount = this.articles.filter(el => el.category_id === this.categories[category].id).length
          }
        }
      }
    },
    selectedDetailedArticle: {
      deep: true,
      handler(val) {
        let index = this.articlesViewUnsliced.findIndex(el => el.id === val.id);
        let page = Math.floor(index / this.countPerPageDefault);
        if (page !== this.page) {
          this.page = page;
          this.getArticlesData();
          let indexA = this.articlesView.findIndex(el => el.id === val.id);
          if (indexA !== -1) this.$refs.article[indexA].scrollIntoView({ block: 'center', behavior: 'smooth' })
        } else {
          let indexA = this.articlesView.findIndex(el => el.id === val.id);
          if (indexA !== -1) this.$refs.article[indexA].scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
      }
    },
    articles: {
      deep: true,
      handler(val) {
        this.articleSearchIndex = createArticleSearchIndex(val);
      }
    },
    '$route.params.id'() {
      this.articlesViewUnsliced = [];
      this.getIntialData();
      this.forceRerender();
    }
  }
}
</script>
<style lang="scss" scoped>
.catalogue {
  padding-bottom: 50px;
  .catalogue__item-header {
    margin-bottom: 10px;
    min-height: 30px;
  }
  &__list {
    width: calc(100% - 216px);
  }
}
</style>
